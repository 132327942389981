import dayjs from 'dayjs';
import { Form, ResponsiveContext } from 'grommet';
import { List } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ApiError,
  ApiRequestOptions,
  ApiResult,
  ClientService,
  EquipmentCategoryService,
  EquipmentService,
  FSERegistrationStatusService,
  FacilityService,
} from '/src/api';
import {
  Box,
  BreadcrumbNav,
  EquipmentUsagesList,
  FormCard,
  FormCardSection,
  FormPage,
  Input,
  Line,
  MeteredEquipmentUsagesList,
  RetireEquipmentModal,
  Select,
} from '/src/components';
import { useClientStore, useEquipmentStore, useEquipmentUsageStore, useGlobalStore, useUserStore } from '/src/context';
import { errorMessages } from '/src/lib/errors';
import {
  Equipment,
  EquipmentCategory,
  EquipmentUsage,
  FSERegistrationStatus,
  Facility,
  TEquipmentId,
  TFacilityId,
  TProgramId,
  TRegionId,
} from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { CountryId, FSERegistrationStatusIds, SelectOptions, TResponseMetadata } from '/src/lib/types';
import { PageNotFound } from '/src/pages';
import {
  equipmentIsRetired,
  facilityIsOrWa,
  filterSelectOptions,
  getFacilityLabel,
  getQuarterFromDate,
} from '/src/utils';

export const EquipmentDetailsPage = observer(() => {
  /*** Context ***/
  const userStore = useUserStore();
  const globalStore = useGlobalStore();
  const equipmentStore = useEquipmentStore();
  const clientStore = useClientStore();
  const equipmentUsageStore = useEquipmentUsageStore();
  const screenSize = useContext(ResponsiveContext);
  const params = useParams();
  const navigate = useNavigate();
  const clientId = parseInt(params.client_id ?? '');
  const equipmentId = parseInt(params.equipment_id ?? '');

  /** State **/
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFacilities, setIsLoadingFacilities] = useState(false);
  const [isLoadingPathways, setIsLoadingPathways] = useState(false);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [showRetireModal, setShowRetireModal] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState<Facility>();
  const [currentFacilityPage, setCurrentFacilityPage] = useState(1);

  const [categories, setCategories] = useState<EquipmentCategory[]>();
  const [fseRegistrationStatuses, setFSERegistrationStatuses] = useState<FSERegistrationStatus[]>();
  const [clientName, setClientName] = useState('');
  const [equipment, setEquipment] = useState<Equipment>();
  const [equipmentUsages, setEquipmentUsages] = useState<EquipmentUsage[]>();
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [facilityOptions, setFacilityOptions] = useState<SelectOptions<TFacilityId>>();
  const [facilityMetadata, setFacilityMetadata] = useState<TResponseMetadata>();
  const [isCanada, setIsCanada] = useState(false);

  /** Computed **/
  const isInvalidParams = Number.isNaN(clientId) || Number.isNaN(equipmentId);
  const isMobile = screenSize === 'small' || screenSize === 'xsmall';
  const programId = selectedFacility?.program?.id;
  const isOrWa = facilityIsOrWa(selectedFacility);
  const isRetired = equipmentIsRetired(equipment);
  const isPending = equipment?.fse_registration_status.id === FSERegistrationStatusIds['Pending Client Information'];
  const isDisabled =
    (userStore.isInternalUser && equipment?.is_finalized) || (userStore.isExternalUser && isPending === false);
  const facilityHasLatLong = !!(selectedFacility?.address_latitude && selectedFacility?.address_longitude);
  const isCustomLatLong =
    equipment && equipment.facility
      ? equipment.latitude !== equipment.facility.address_latitude ||
        equipment.longitude !== equipment.facility.address_longitude
      : false;
  const isForklift = !!(equipmentStore.formValues.category_name ?? '').toLowerCase().includes('forklift');

  const equipmentTypeOptions =
    categories
      ?.find((category) => category.id === equipmentStore.formValues.equipment_category_id)
      ?.equipment_types?.map((type) => ({
        label: type.name,
        value: type.id,
      })) ?? [];

  const lastReportingPeriod = isCanada
    ? equipment?.last_active_reporting_quarter?.slice(0, 4)
    : getQuarterFromDate(dayjs(equipment?.last_active_reporting_quarter).toDate());

  /** Methods **/
  const fetchPageData = async (equipmentId: TEquipmentId) => {
    setIsLoading(true);
    try {
      const [equipment, { data: facilitiesData, meta: facilitiesMeta }, fseRegistrationStatuses] = await Promise.all([
        EquipmentService.get({ id: equipmentId }),
        FacilityService.list({ client_id: clientId, page: currentFacilityPage }),
        FSERegistrationStatusService.listFSERegistrationStatuses(),
      ]);

      const client =
        userStore.isClientUser || userStore.isFacilityUser
          ? userStore.user?.clients.find((client) => client.id === clientId)
          : await ClientService.get({ id: clientId });

      if (client) {
        await clientStore.fetchClientReportingPeriods(client.id, client.reporting_period_type_id);
        setClientName(client.name);
        setIsCanada(client.hq_address_region.country_id === CountryId.Canada);
      }

      setEquipment(equipment);
      const categoryName = equipment.equipment_type.equipment_category?.name;

      setFacilities(facilitiesData);
      setFacilityMetadata(facilitiesMeta);
      setFacilityOptions([
        ...(facilityOptions ?? []),
        ...facilitiesData.map((facility) => ({ label: getFacilityLabel(facility, true), value: facility.id })),
      ]);
      setSelectedFacility(equipment.facility);
      const programId = equipment.facility?.program?.id;

      setFSERegistrationStatuses(fseRegistrationStatuses);

      const categories = await EquipmentCategoryService.listEquipmentCategories({ program_id: programId });
      setCategories(categories);

      const equipmentUsages = await equipmentUsageStore.listEquipmentUsages({
        equipment_id: equipment.id,
      });
      setEquipmentUsages(equipmentUsages);

      equipmentStore.setFormValues({
        ...equipmentStore.formValues,
        facility_id: equipment.facility_id ?? NaN,
        category_name: categoryName ?? '',
        equipment_type_id: equipment.equipment_type_id ?? NaN,
        equipment_category_id: equipment.equipment_type.equipment_category_id ?? NaN,
        fuel_pathway_id: equipment.fuel_pathway_id ?? NaN,
        unit_number: equipment.unit_number ?? '',
        serial_number: equipment.serial_number ?? '',
        manufacturer: equipment.manufacturer ?? '',
        model_number: equipment.model_number ?? '',
        model_year: equipment.model_year?.toString() ?? '',
        is_metered: equipment.is_metered,
        first_day_in_service: equipment.first_day_in_service,
        first_active_reporting_quarter: equipment.first_active_reporting_quarter,
        last_active_reporting_quarter: equipment.last_active_reporting_quarter,
        battery_capacity_rating_ah: equipment.battery_capacity_rating_ah?.toString() ?? '',
        voltage: equipment.voltage?.toString() ?? '',
        is_book_and_claim_applied: equipment.is_book_and_claim_applied,
        latitude: equipment.latitude?.toString() ?? '',
        longitude: equipment.longitude?.toString() ?? '',
        fse_registration_status_id: equipment.fse_registration_status_id ?? NaN,
        fse_id: equipment.fse_id ?? '',
        fse_ru: equipment.fse_ru ?? '',
        comments: equipment.comments ?? '',
      });
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.generic.error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFuelPathways = async (programId: TProgramId, regionId: TRegionId) => {
    try {
      setIsLoadingPathways(true);
      await equipmentStore.fetchFuelPathways(programId, regionId);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.generic.error);
    } finally {
      setIsLoadingPathways(false);
    }
  };

  const fetchCategories = async (programId: TProgramId) => {
    setIsLoadingCategories(true);
    try {
      const categories = await EquipmentCategoryService.listEquipmentCategories({ program_id: programId });
      setCategories(categories);
      return categories;
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.generic.error);
    } finally {
      setIsLoadingCategories(false);
    }
  };

  const nextFacilityPage = async () => {
    if (isLoadingFacilities || (facilityMetadata && currentFacilityPage + 1 > facilityMetadata.last_page)) return;
    setIsLoadingFacilities(true);
    try {
      setCurrentFacilityPage(currentFacilityPage + 1);
      const { meta, data } = await FacilityService.list({
        client_id: clientId,
        page: currentFacilityPage + 1,
      });
      setFacilities([...facilities, ...data]);
      setFacilityOptions([
        ...(facilityOptions ?? []),
        ...data.map((facility) => ({ label: getFacilityLabel(facility), value: facility.id })),
      ]);
      setFacilityMetadata(meta);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.fetchEquipment.error);
    } finally {
      setIsLoadingFacilities(false);
    }
  };

  const searchPathways = (nameFilter: string) => {
    if (nameFilter === '') equipmentStore.setFuelPathwayOptions(equipmentStore.defaultFuelPathwayOptions);
    else {
      const filteredPathways = filterSelectOptions(nameFilter, equipmentStore.defaultFuelPathwayOptions);
      equipmentStore.setFuelPathwayOptions(filteredPathways);
    }
  };

  const updateEquipment = async () => {
    if (!equipment || !userStore.user) return;

    try {
      setIsUpdating(true);

      let updatedEquipment: Equipment | undefined;

      if (userStore.isInternalUser) {
        updatedEquipment = equipment.is_finalized
          ? await equipmentStore.updateFinalizedInternal(equipment.id)
          : await equipmentStore.updateNonFinalizedInternal(equipment);
      } else if (userStore.isExternalUser) {
        updatedEquipment = isPending
          ? await equipmentStore.updatePendingExternal(equipment.id)
          : await equipmentStore.updateNonPendingExternal(equipment.id);
      }

      if (!updatedEquipment)
        throw new ApiError({} as ApiRequestOptions, {} as ApiResult, toastMessages.updateEquipment.error(undefined));

      const equipmentUsages = await equipmentUsageStore.listEquipmentUsages({
        equipment_id: equipment.id,
      });
      setEquipmentUsages(equipmentUsages);
      setEquipment(updatedEquipment);
      toast.success(toastMessages.updateEquipment.success);
    } catch (err) {
      const error = err as ApiError;
      let showToast = true;
      if (error?.status === 400) {
        if (error?.body?.message?.toLowerCase().includes('first active reporting quarter')) {
          equipmentStore.formErrors['first_active_reporting_quarter'] = error.body.message;
          showToast = false;
        }
      }
      if (showToast) globalStore.handleApiError(error, toastMessages.updateEquipment.error);
    } finally {
      setIsUpdating(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    equipmentStore.resetForm();
  }, []);

  // Fetch page data
  useEffect(() => {
    if (equipmentId) {
      fetchPageData(equipmentId).finally(() => {
        if (shouldRefresh) setShouldRefresh(false);
      });
    }
  }, [equipmentId]);

  useEffect(() => {
    if (equipmentStore.formValues.is_metered) {
      equipmentStore.updateFormValue('battery_capacity_rating_ah', undefined);
      equipmentStore.updateFormValue('voltage', undefined);
    }
  }, [equipmentStore.formValues?.is_metered]);

  useEffect(() => {
    if (programId && selectedFacility?.address_region_id) {
      if (equipmentStore.formValues.category_name) fetchCategories(programId);
      fetchFuelPathways(programId, selectedFacility.address_region_id);
    }
  }, [equipmentStore.formValues.category_name, selectedFacility?.address_region_id, programId]);

  // Change Category
  useEffect(() => {
    if (categories) {
      const category = categories?.find(({ name }) => name === equipmentStore.formValues.category_name);
      equipmentStore.updateFormValue('equipment_category_id', category?.id);
    }
  }, [categories, equipmentStore.formValues?.category_name]);

  // Change Facility
  useEffect(() => {
    if (equipmentStore.formValues.facility_id && selectedFacility?.id !== equipmentStore.formValues.facility_id) {
      const facility = facilities.find((facility) => facility.id === equipmentStore.formValues.facility_id);
      setSelectedFacility(facility);
    }
  }, [equipmentStore.formValues.facility_id, selectedFacility, facilities]);

  // Set Book & Claim to default if null
  useEffect(() => {
    if (equipmentStore.formValues.is_book_and_claim_applied === null) {
      equipmentStore.updateFormValue(
        'is_book_and_claim_applied',
        equipmentStore.defaultFormValues.is_book_and_claim_applied
      );
    }
  }, [equipmentStore.defaultFormValues.is_book_and_claim_applied, equipmentStore.formValues.is_book_and_claim_applied]);

  /** Render **/
  const renderMetered = () => (
    <Select
      id="is_metered"
      label="Metered"
      value={equipmentStore.formValues.is_metered ? 'Yes' : 'No'}
      setValue={(value) => equipmentStore.updateFormValue('is_metered', value === 'Yes')}
      options={['Yes', 'No']}
      error={equipmentStore.formErrors['is_metered']}
      disabled={isDisabled || isCanada}
      required
      fill="horizontal"
    />
  );

  const renderBookAndClaim = () => (
    <Select
      id="is_book_and_claim_applied"
      label="Book & Claim"
      value={
        equipmentStore.formValues.is_book_and_claim_applied === null
          ? undefined
          : equipmentStore.formValues.is_book_and_claim_applied
          ? 'Applied'
          : 'Not Applied'
      }
      setValue={(value) => equipmentStore.updateFormValue('is_book_and_claim_applied', value === 'Applied')}
      options={['Applied', 'Not Applied']}
      error={equipmentStore.formErrors['is_book_and_claim_applied']}
      required={equipmentStore.isApproved}
      disabled={isDisabled}
      fill="horizontal"
    />
  );

  const renderFirstReportingPeriod = () => (
    <Select
      id="first_active_reporting_quarter"
      label="First Reporting Period"
      placeholder="Select Period..."
      value={equipmentStore.formValues.first_active_reporting_quarter}
      setValue={(value) => equipmentStore.updateFormValue('first_active_reporting_quarter', value)}
      options={clientStore.reportingPeriodOptions ?? []}
      error={equipmentStore.formErrors['first_active_reporting_quarter']}
      disabled={isDisabled}
      required={equipmentStore.isApproved}
      fill="horizontal"
    />
  );

  const renderFirstDayInService = () => (
    <Input
      id="first_day_in_service"
      label="First Day in Service"
      fill="horizontal"
      value={equipmentStore.formValues.first_day_in_service ?? ''}
      setValue={(value) => equipmentStore.updateFormValue('first_day_in_service', value)}
      error={equipmentStore.formErrors['first_day_in_service']}
      onSubmit={updateEquipment}
      disabled={isDisabled}
      componentType="date"
      required={isOrWa}
    />
  );

  const renderRetirementPeriod = () => (
    <Select
      label="Retirement Period"
      placeholder="Still Active"
      value={lastReportingPeriod}
      setValue={() => {}}
      options={[]}
      disabled
      required
      fill="horizontal"
    />
  );

  return isInvalidParams ? (
    <PageNotFound />
  ) : (
    <FormPage
      title={equipment?.name ?? `${equipment?.manufacturer}: ${equipment?.serial_number}`}
      breadcrumbNav={
        <BreadcrumbNav
          previousPages={[
            { name: 'Clients', link: !userStore.isClientUser && !userStore.isFacilityUser ? '/clients' : undefined },
            { name: clientName, link: !userStore.isFacilityUser ? `/clients/${clientId}` : undefined },
            { name: 'Facilities', link: `/clients/${clientId}/facilities` },
            {
              name: getFacilityLabel(selectedFacility),
              link: `/clients/${clientId}/facilities/${selectedFacility?.id ?? ''}`,
            },
            { name: 'Equipment', link: `/clients/${clientId}/facilities/${selectedFacility?.id ?? ''}/equipment` },
          ]}
          currentPageName={`${equipment?.manufacturer}: ${equipment?.serial_number}`}
        />
      }
      isLoading={isLoading}
      isDetailPage
      showMenuButton
      menuButtonItems={[{ label: 'Retire Equipment', onClick: () => setShowRetireModal(true), disabled: isRetired }]}
    >
      <FormCard
        title="Equipment Details"
        icon={<List size="24px" color="brand" />}
        onSubmit={updateEquipment}
        isLoading={isLoading || isUpdating}
        hideSaveButton={!userStore.isAdminUser}
      >
        <Form>
          <Box direction={isMobile ? 'column' : 'row'} margin={{ bottom: '1rem' }}>
            <Box width="60%" gap="1rem">
              <FormCardSection row>
                <Select
                  id="category_name"
                  label="Equipment Category"
                  value={equipmentStore.formValues.category_name}
                  setValue={(value) => equipmentStore.updateFormValue('category_name', value)}
                  options={categories?.map((category) => category.name) ?? []}
                  error={equipmentStore.formErrors['category_name']}
                  disabled={isDisabled}
                  required
                  fill="horizontal"
                />
                <Select
                  id="equipment_type_id"
                  label="Equipment Type"
                  placeholder="Choose..."
                  value={
                    equipmentTypeOptions.length === 1 && !equipmentStore.formValues.equipment_type_id
                      ? equipmentTypeOptions[0].value
                      : equipmentStore.formValues.equipment_type_id
                  }
                  setValue={(value) => equipmentStore.updateFormValue('equipment_type_id', value)}
                  options={equipmentTypeOptions}
                  fill="horizontal"
                  error={equipmentStore.formErrors['equipment_type_id']}
                  required
                  disabled={!userStore.isAdminUser || isDisabled}
                />
              </FormCardSection>
              <Line margin="0.5rem" />
              <FormCardSection>
                <Box direction="row" gap="1rem">
                  <Input
                    id="serial_number"
                    label="Serial Number"
                    value={equipmentStore.formValues.serial_number}
                    setValue={(value) => equipmentStore.updateFormValue('serial_number', value)}
                    error={equipmentStore.formErrors['serial_number']}
                    onSubmit={updateEquipment}
                    fill="horizontal"
                    required
                    disabled={!userStore.isAdminUser || isDisabled}
                  />
                  <Input
                    id="unit_number"
                    label="Unit Number"
                    value={equipmentStore.formValues.unit_number ?? ''}
                    setValue={(value) => equipmentStore.updateFormValue('unit_number', value)}
                    error={equipmentStore.formErrors['unit_number']}
                    onSubmit={updateEquipment}
                    fill="horizontal"
                    disabled={!userStore.isAdminUser || isDisabled}
                  />
                </Box>
                <Box direction="row" gap="1rem">
                  <Input
                    id="model_year"
                    label="Model Year"
                    value={equipmentStore.formValues.model_year}
                    setValue={(value) => equipmentStore.updateFormValue('model_year', value)}
                    error={equipmentStore.formErrors['model_year']}
                    onSubmit={updateEquipment}
                    type="number"
                    fill="horizontal"
                    required
                    disabled={!userStore.isAdminUser || isDisabled}
                  />
                  <Input
                    id="manufacturer"
                    label="Manufacturer"
                    value={equipmentStore.formValues.manufacturer}
                    setValue={(value) => equipmentStore.updateFormValue('manufacturer', value)}
                    error={equipmentStore.formErrors['manufacturer']}
                    onSubmit={updateEquipment}
                    fill="horizontal"
                    required
                    disabled={!userStore.isAdminUser || isDisabled}
                  />
                  <Input
                    id="model_number"
                    label="Model Number"
                    value={equipmentStore.formValues.model_number}
                    setValue={(value) => equipmentStore.updateFormValue('model_number', value)}
                    error={equipmentStore.formErrors['model_number']}
                    onSubmit={updateEquipment}
                    fill="horizontal"
                    disabled={!userStore.isAdminUser || isDisabled}
                  />
                </Box>
              </FormCardSection>
              <Line margin="xsmall" />

              <FormCardSection>
                {userStore.isInternalUser && (isOrWa || isRetired) && (
                  <Box direction="row" gap="1rem">
                    {isOrWa && renderFirstDayInService()}
                    {renderFirstReportingPeriod()}
                    {isRetired && renderRetirementPeriod()}
                  </Box>
                )}
                <Box direction="row" gap="1rem">
                  {renderMetered()}
                  {userStore.isInternalUser && renderBookAndClaim()}
                  {userStore.isInternalUser && !isOrWa && !isRetired && renderFirstReportingPeriod()}
                  {userStore.isExternalUser && isOrWa && renderFirstDayInService()}
                  {userStore.isExternalUser && isRetired && renderRetirementPeriod()}
                </Box>

                {!equipmentStore.formValues.is_metered && <Line margin="xsmall" />}

                {!equipmentStore.formValues.is_metered && (
                  <Box direction="row" gap="1rem">
                    <Input
                      id="battery_capacity_rating_ah"
                      label="Battery Capacity (Ah)"
                      value={
                        !equipmentStore.formValues.is_metered
                          ? equipmentStore.formValues.battery_capacity_rating_ah
                          : ''
                      }
                      setValue={(value) => equipmentStore.updateFormValue('battery_capacity_rating_ah', value)}
                      error={equipmentStore.formErrors['battery_capacity_rating_ah']}
                      onSubmit={updateEquipment}
                      fill="horizontal"
                      type="number"
                      placeholder={
                        equipmentStore.formValues.is_metered ? 'Not applicable to metered equipment.' : undefined
                      }
                      disabled={equipmentStore.formValues.is_metered || isDisabled}
                      required={!equipmentStore.formValues.is_metered}
                      hideOptionalText
                    />
                    <Input
                      id="voltage"
                      label="Voltage"
                      value={!equipmentStore.formValues.is_metered ? equipmentStore.formValues.voltage : ''}
                      setValue={(value) => equipmentStore.updateFormValue('voltage', value)}
                      error={equipmentStore.formErrors['voltage']}
                      onSubmit={updateEquipment}
                      fill="horizontal"
                      type="number"
                      placeholder={
                        equipmentStore.formValues.is_metered ? 'Not applicable to metered equipment.' : undefined
                      }
                      disabled={equipmentStore.formValues.is_metered || isDisabled}
                      required={!equipmentStore.formValues.is_metered}
                      hideOptionalText
                    />
                  </Box>
                )}
              </FormCardSection>
              <Line margin="0.5rem" />
              <Box flex="grow">
                <Input
                  id="comments"
                  label="Comments"
                  value={equipmentStore.formValues.comments ?? ''}
                  setValue={(value) => equipmentStore.updateFormValue('comments', value)}
                  error={equipmentStore.formErrors['comments']}
                  onSubmit={updateEquipment}
                  componentType="textArea"
                  centerLabel={false}
                  height="12rem"
                  disabled={!userStore.isAdminUser}
                />
              </Box>
            </Box>
            <Line direction="vertical" margin="3rem" />
            <Box gap="1rem" width="40%">
              <FormCardSection>
                <Select
                  id="facility_id"
                  label="Facility"
                  value={equipmentStore.formValues.facility_id}
                  setValue={(value) => equipmentStore.updateFormValue('facility_id', value)}
                  onMore={nextFacilityPage}
                  options={facilityOptions ?? []}
                  error={equipmentStore.formErrors['facility_id']}
                  required
                  disabled
                />
              </FormCardSection>
              {userStore.isInternalUser && <Line margin="0.5rem" />}
              {userStore.isInternalUser && (
                <FormCardSection title="FSE">
                  <Select
                    label="FSE Registration Status"
                    id="fse_registration_status_id"
                    placeholder="Choose..."
                    value={equipmentStore.formValues.fse_registration_status_id}
                    setValue={(value) => equipmentStore.updateFormValue('fse_registration_status_id', value)}
                    options={(fseRegistrationStatuses ?? []).map((s) => ({ label: s.name, value: s.id }))}
                    error={equipmentStore.formErrors['fse_registration_status_id']}
                    required
                    disabled={!userStore.isAdminUser || isDisabled}
                  />
                  <Input
                    id="fse_id"
                    label="FSE ID"
                    value={equipmentStore.formValues.fse_id ?? ''}
                    setValue={(value) => equipmentStore.updateFormValue('fse_id', value)}
                    error={equipmentStore.formErrors['fse_id']}
                    onSubmit={updateEquipment}
                    required={equipmentStore.isApproved}
                    disabled={!userStore.isAdminUser || isDisabled}
                  />
                  <Input
                    id="fse_ru"
                    label="FSE RU"
                    value={equipmentStore.formValues.fse_ru ?? ''}
                    setValue={(value) => equipmentStore.updateFormValue('fse_ru', value)}
                    error={equipmentStore.formErrors['fse_ru']}
                    onSubmit={updateEquipment}
                    disabled={!userStore.isAdminUser || isDisabled}
                  />
                </FormCardSection>
              )}
              {userStore.isInternalUser && <Line margin="0.5rem" />}
              {userStore.isInternalUser && (
                <FormCardSection title="Fuel Pathway">
                  <Select
                    id="fuel_pathway_id"
                    label="Fuel Pathway Code"
                    placeholder={!isLoadingPathways ? 'Choose...' : 'Loading...'}
                    value={!isLoadingPathways ? equipmentStore.formValues.fuel_pathway_id : ''}
                    setValue={(value) => equipmentStore.updateFormValue('fuel_pathway_id', value)}
                    options={equipmentStore.fuelPathwayOptions}
                    error={equipmentStore.formErrors['fuel_pathway_id']}
                    onSearch={searchPathways}
                    searchPlaceholder="Filter by Code..."
                    emptySearchMessage={errorMessages.fuelPathwayNameSearchNoResult}
                    required={equipmentStore.isApproved}
                    fill="horizontal"
                    disabled={!userStore.isAdminUser || isDisabled || isLoadingPathways}
                  />
                </FormCardSection>
              )}
              <Line margin="0.5rem" />
              <FormCardSection title="Location">
                <Box direction="row" gap="1rem">
                  <Input
                    id="latitude"
                    label="Latitude"
                    value={equipmentStore.formValues.latitude}
                    type="number"
                    setValue={(value) => equipmentStore.updateFormValue('latitude', value)}
                    error={equipmentStore.formErrors['latitude']}
                    onSubmit={updateEquipment}
                    fill="horizontal"
                    placeholder="DDD.DDDDD°"
                    required
                    disabled={!userStore.isAdminUser}
                  />
                  <Input
                    id="longitude"
                    label="Longitude"
                    value={equipmentStore.formValues.longitude}
                    type="number"
                    setValue={(value) => equipmentStore.updateFormValue('longitude', value)}
                    error={equipmentStore.formErrors['longitude']}
                    onSubmit={updateEquipment}
                    fill="horizontal"
                    placeholder="DDD.DDDDD°"
                    required
                    disabled={!userStore.isAdminUser}
                  />
                </Box>
              </FormCardSection>
            </Box>
          </Box>
        </Form>
      </FormCard>
      {equipment && !!equipmentUsages?.length && (
        <Box animation={[{ type: 'slideDown', duration: 300, size: 'xsmall' }]}>
          {equipment.is_metered && (
            <MeteredEquipmentUsagesList equipment={equipment} equipmentUsagesList={equipmentUsages} />
          )}
          {!equipment.is_metered && <EquipmentUsagesList equipment={equipment} equipmentUsagesList={equipmentUsages} />}
        </Box>
      )}
      {showRetireModal && !!equipment && (
        <RetireEquipmentModal
          equipment={equipment}
          setIsVisible={setShowRetireModal}
          setShouldRefresh={setShouldRefresh}
        />
      )}
    </FormPage>
  );
});
