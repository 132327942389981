import { Form, ResponsiveContext } from 'grommet';
import { List } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ApiError,
  ClientService,
  EquipmentCategoryService,
  FSERegistrationStatusService,
  FacilityService,
} from '/src/api';
import {
  Box,
  BreadcrumbNav,
  BreadcrumbNavProps,
  FormCard,
  FormCardSection,
  FormPage,
  Input,
  Line,
  Select,
} from '/src/components';
import { useClientStore, useEquipmentStore, useGlobalStore, useUserStore } from '/src/context';
import { errorMessages } from '/src/lib/errors';
import {
  EquipmentCategory,
  FSERegistrationStatus,
  Facility,
  Region,
  TFacilityId,
  TProgramId,
  TRegionId,
} from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { SelectOptions, TResponseMetadata } from '/src/lib/types';
import { facilityIsOrWa, filterSelectOptions, getFacilityLabel, getIsCanada, getQueryParams } from '/src/utils';

export const CreateEquipmentPage = observer(() => {
  /* Context */
  const navigate = useNavigate();
  const globalStore = useGlobalStore();
  const clientStore = useClientStore();
  const equipmentStore = useEquipmentStore();
  const userStore = useUserStore();
  const screenSize = useContext(ResponsiveContext);
  const isMobile = screenSize === ('small' || 'xsmall');
  const { search } = useLocation();
  const queryParams = getQueryParams(search);
  const categoryName = queryParams.category?.replace(/%20/g, ' ') ?? '';
  const facilityId = parseInt(queryParams.facility_id ?? '');
  const params = useParams();
  const clientId = parseInt(params.client_id ?? '');

  /* State */
  const [isLoadingFacilities, setIsLoadingFacilities] = useState(false);
  const [isLoadingPathways, setIsLoadingPathways] = useState(false);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState<Facility>();
  const [currentFacilityPage, setCurrentFacilityPage] = useState(1);
  const [isUserLocation, setIsUserLocation] = useState(true);

  const [categories, setCategories] = useState<EquipmentCategory[]>();
  const [fseRegistrationStatuses, setFSERegistrationStatuses] = useState<FSERegistrationStatus[]>();
  const [clientName, setClientName] = useState('');
  const [clientRegion, setClientRegion] = useState<Region>();

  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [facilityOptions, setFacilityOptions] = useState<SelectOptions<TFacilityId>>([]);
  const [facilityMetadata, setFacilityMetadata] = useState<TResponseMetadata>();
  const [breadcrumbNavItems, setBreadcrumbNavItems] = useState<BreadcrumbNavProps['previousPages']>();

  /* Computed */
  const regionId = selectedFacility?.address_region_id;
  const isCanada = getIsCanada(clientRegion);
  const isLoading = !clientName || !fseRegistrationStatuses || !facilityOptions || !facilities;
  const isOrWa = facilityIsOrWa(selectedFacility);
  const isForklift = !!(equipmentStore.formValues.category_name ?? '').toLowerCase().includes('forklift');

  /* Memos */
  const programId = selectedFacility?.program?.id;

  const equipmentTypeOptions =
    categories
      ?.find(({ name }) => name === equipmentStore.formValues.category_name)
      ?.equipment_types?.map((type) => ({
        label: type.name,
        value: type.id,
      })) ?? [];

  /* Methods */
  const fetchPageData = async () => {
    try {
      const [{ data: facilitiesData, meta: facilitiesMeta }, registrationStatuses] = await Promise.all([
        FacilityService.list({ client_id: clientId, page: currentFacilityPage }),
        FSERegistrationStatusService.listFSERegistrationStatuses(),
      ]);

      const client =
        userStore.isClientUser || userStore.isFacilityUser
          ? userStore.user?.clients.find((client) => client.id === clientId)
          : await ClientService.get({ id: clientId });

      if (client) {
        await clientStore.fetchClientReportingPeriods(client.id, client.reporting_period_type_id);
        setClientName(client.name);
        setClientRegion(client.hq_address_region);
      }

      setFacilities(facilitiesData);
      setFacilityMetadata(facilitiesMeta);
      setFacilityOptions([
        ...facilityOptions,
        ...facilitiesData.map((facility) => ({ label: getFacilityLabel(facility, true), value: facility.id })),
      ]);
      const facility = facilityId ? facilitiesData.find((facility) => facility.id === facilityId) : selectedFacility;
      setSelectedFacility(facility);
      fetchNavItems(client?.name ?? '', facility);
      const programId = facility?.program?.id;

      setFSERegistrationStatuses(registrationStatuses);

      let category: EquipmentCategory | undefined;
      if (programId) {
        const categories = await EquipmentCategoryService.listEquipmentCategories({ program_id: programId });
        setCategories(categories);
        category = categories?.find((category) => category.name === categoryName);
      }

      equipmentStore.setFormValues({
        ...equipmentStore.formValues,
        facility_id: facilityId,
        category_name: categoryName,
        equipment_category_id: category?.id ?? NaN,
        is_metered: isCanada ? true : equipmentStore.formValues.is_metered,
      });
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.generic.error);
    }
  };

  const fetchFuelPathways = async (programId: TProgramId, regionId: TRegionId) => {
    setIsLoadingPathways(true);
    try {
      const pathways = await equipmentStore.fetchFuelPathways(programId, regionId);
      if (selectedFacility?.utility?.id) {
        const defaultPathway = pathways.find((pathway) => pathway.default_utility?.id === selectedFacility?.utility.id);
        if (defaultPathway) {
          equipmentStore.setFormValues({
            ...equipmentStore.formValues,
            fuel_pathway_id: defaultPathway.id,
          });
        }
      }
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.generic.error);
    } finally {
      setIsLoadingPathways(false);
    }
  };

  const fetchCategories = async (programId: TProgramId) => {
    setIsLoadingCategories(true);
    try {
      const categories = await EquipmentCategoryService.listEquipmentCategories({ program_id: programId });
      setCategories(categories);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.generic.error);
    } finally {
      setIsLoadingCategories(false);
    }
  };

  const fetchNavItems = (clientName: string, facility?: Facility) => {
    let navItems: BreadcrumbNavProps['previousPages'] = [
      { name: 'Clients', link: !userStore.isClientUser && !userStore.isFacilityUser ? '/clients' : undefined },
      { name: clientName, link: !userStore.isFacilityUser ? `/clients/${clientId}` : undefined },
    ];
    if (facility) {
      navItems.push({ name: 'Facilities', link: `/clients/${clientId}/facilities` });
      navItems.push({
        name: getFacilityLabel(facility),
        link: `/clients/${clientId}/facilities/${facility.id}`,
      });
      navItems.push({ name: 'Equipment', link: `/clients/${clientId}/facilities/${facility.id}/equipment` });
    } else {
      navItems.push({ name: 'Equipment' });
    }
    setBreadcrumbNavItems(navItems);
  };

  const nextFacilityPage = async () => {
    if (isLoadingFacilities || (facilityMetadata && currentFacilityPage + 1 > facilityMetadata.last_page)) return;
    setIsLoadingFacilities(true);
    try {
      setCurrentFacilityPage(currentFacilityPage + 1);
      const { meta, data } = await FacilityService.list({
        client_id: clientId,
        page: currentFacilityPage + 1,
      });
      setFacilities([...facilities, ...data]);
      setFacilityOptions([
        ...facilityOptions,
        ...data.map((facility) => ({ label: getFacilityLabel(facility), value: facility.id })),
      ]);
      setFacilityMetadata(meta);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.fetchEquipment.error);
    } finally {
      setIsLoadingFacilities(false);
    }
  };

  const searchPathways = (nameFilter: string) => {
    if (nameFilter === '') equipmentStore.setFuelPathwayOptions(equipmentStore.defaultFuelPathwayOptions);
    else {
      const filteredPathways = filterSelectOptions(nameFilter, equipmentStore.defaultFuelPathwayOptions ?? []);
      equipmentStore.setFuelPathwayOptions(filteredPathways);
    }
  };

  const createEquipment = async () => {
    if (!selectedFacility) return;

    try {
      setIsCreating(true);
      const equipment = await (userStore.isInternalUser
        ? equipmentStore.createEquipmentInternal(selectedFacility)
        : equipmentStore.createEquipmentExternal(selectedFacility));
      if (equipment) {
        toast.success(toastMessages.createEquipment.success);
        navigate(`/clients/${clientId}/equipment/${equipment.id}`);
      }
    } catch (err) {
      const error = err as ApiError;
      let showToast = true;
      if (error.status === 400) {
        if (error.body?.message?.toLowerCase().includes('first active reporting quarter')) {
          equipmentStore.formErrors['first_active_reporting_quarter'] = error.body?.message;
          showToast = false;
        }
      }
      if (showToast) globalStore.handleApiError(error, toastMessages.createEquipment.error);
    } finally {
      setIsCreating(false);
    }
  };

  /* Effects */
  useEffect(() => {
    equipmentStore.resetForm();
  }, []);

  // Fetch page data
  useEffect(() => {
    if (isLoading) {
      fetchPageData();
    }
  }, [isLoading]);

  useEffect(() => {
    if (equipmentStore.formValues.is_metered) {
      equipmentStore.updateFormValue('battery_capacity_rating_ah', undefined);
      equipmentStore.updateFormValue('voltage', undefined);
    }
  }, [equipmentStore.formValues?.is_metered]);

  useEffect(() => {
    if (programId && regionId) {
      if (equipmentStore.formValues.category_name) fetchCategories(programId);
      fetchFuelPathways(programId, regionId);
    }
  }, [equipmentStore.formValues.category_name, programId, regionId]);

  // Auto-fill lat/long (if applicable)
  useEffect(() => {
    const latLongIsEmpty = !equipmentStore.formValues.latitude && !equipmentStore.formValues.longitude;
    if (
      isForklift &&
      (latLongIsEmpty || !isUserLocation) &&
      selectedFacility?.address_latitude &&
      selectedFacility?.address_longitude
    ) {
      equipmentStore.updateFormValue('latitude', selectedFacility.address_latitude);
      equipmentStore.updateFormValue('longitude', selectedFacility.address_longitude);
      setIsUserLocation(false);
    }
  }, [
    isForklift,
    equipmentStore.formValues.latitude,
    equipmentStore.formValues.longitude,
    isUserLocation,
    selectedFacility?.address_latitude,
    selectedFacility?.address_longitude,
  ]);

  // Change Category
  useEffect(() => {
    if (categories) {
      const category = categories?.find(({ name }) => name === equipmentStore.formValues.category_name);

      const options =
        category?.equipment_types?.map((type) => ({
          label: type.name,
          value: type.id,
        })) ?? [];

      equipmentStore.updateFormValue('equipment_category_id', category?.id);
      equipmentStore.updateFormValue('equipment_type_id', options?.length === 1 ? options[0].value : NaN);
    }
  }, [categories, equipmentStore.formValues?.category_name]);

  // Change Facility
  useEffect(() => {
    if (equipmentStore.formValues.facility_id && selectedFacility?.id !== equipmentStore.formValues.facility_id) {
      const facility = facilities.find((facility) => facility.id === equipmentStore.formValues.facility_id);
      setSelectedFacility(facility);
      fetchNavItems(clientName, facility);
    }
  }, [equipmentStore.formValues.facility_id, selectedFacility, facilities, clientName]);

  /* Render */
  const renderMetered = () => (
    <Select
      id="is_metered"
      label="Metered"
      value={equipmentStore.formValues.is_metered ? 'Yes' : 'No'}
      setValue={(value) => equipmentStore.updateFormValue('is_metered', value === 'Yes')}
      options={['Yes', 'No']}
      error={equipmentStore.formErrors['is_metered']}
      required
      disabled={isCanada}
      fill="horizontal"
    />
  );

  const renderBookAndClaim = () => (
    <Select
      id="is_book_and_claim_applied"
      label="Book & Claim"
      value={
        equipmentStore.formValues.is_book_and_claim_applied === null
          ? undefined
          : equipmentStore.formValues.is_book_and_claim_applied
          ? 'Applied'
          : 'Not Applied'
      }
      setValue={(value) => equipmentStore.updateFormValue('is_book_and_claim_applied', value === 'Applied')}
      options={['Applied', 'Not Applied']}
      error={equipmentStore.formErrors['is_book_and_claim_applied']}
      required={equipmentStore.isApproved}
      fill="horizontal"
    />
  );

  const renderFirstReportingPeriod = () => (
    <Select
      id="first_active_reporting_quarter"
      label="First Reporting Period"
      placeholder="Select Period..."
      value={equipmentStore.formValues.first_active_reporting_quarter}
      setValue={(value) => equipmentStore.updateFormValue('first_active_reporting_quarter', value)}
      options={clientStore.reportingPeriodOptions ?? []}
      error={equipmentStore.formErrors['first_active_reporting_quarter']}
      required={equipmentStore.isApproved}
      fill="horizontal"
    />
  );

  const renderFirstDayInService = () => (
    <Input
      id="first_day_in_service"
      label="First Day in Service"
      fill="horizontal"
      value={equipmentStore.formValues.first_day_in_service ?? ''}
      setValue={(value) => equipmentStore.updateFormValue('first_day_in_service', value)}
      error={equipmentStore.formErrors['first_day_in_service']}
      onSubmit={createEquipment}
      componentType="date"
      required={isOrWa}
    />
  );

  return (
    <FormPage
      title={`Add ${equipmentStore.formValues.category_name || 'Equipment'}`}
      breadcrumbNav={
        <BreadcrumbNav
          previousPages={breadcrumbNavItems ?? []}
          currentPageName={`Add ${equipmentStore.formValues.category_name || 'Equipment'}`}
        />
      }
      isLoading={isLoading}
    >
      <FormCard
        title="Equipment Details"
        icon={<List size="24px" color="brand" />}
        isLoading={isLoading || isCreating}
        onSubmit={createEquipment}
      >
        <Form>
          <Box direction={isMobile ? 'column' : 'row'} margin={{ bottom: '1rem' }}>
            <Box width="60%" gap="1rem">
              <FormCardSection row>
                <Select
                  id="category_name"
                  label="Equipment Category"
                  placeholder="Choose..."
                  value={equipmentStore.formValues.category_name}
                  setValue={(value) => equipmentStore.updateFormValue('category_name', value)}
                  options={categories?.map((category) => category.name) ?? []}
                  error={equipmentStore.formErrors['category_name']}
                  emptySearchMessage="No Facility selected."
                  required
                  width="50%"
                />
                <Select
                  id="equipment_type_id"
                  label="Equipment Type"
                  placeholder="Choose..."
                  emptySearchMessage="No Equipment Category selected."
                  value={equipmentStore.formValues.equipment_type_id}
                  setValue={(value) => equipmentStore.updateFormValue('equipment_type_id', value)}
                  options={equipmentTypeOptions}
                  width="50%"
                  error={equipmentStore.formErrors['equipment_type_id']}
                  required
                />
              </FormCardSection>
              <Line margin="0.5rem" />
              <FormCardSection>
                <Box direction="row" gap="1rem">
                  <Input
                    id="serial_number"
                    label="Serial Number"
                    value={equipmentStore.formValues.serial_number}
                    setValue={(value) => equipmentStore.updateFormValue('serial_number', value)}
                    error={equipmentStore.formErrors['serial_number']}
                    onSubmit={createEquipment}
                    fill="horizontal"
                    required
                  />
                  <Input
                    id="unit_number"
                    label="Unit Number"
                    value={equipmentStore.formValues.unit_number ?? ''}
                    setValue={(value) => equipmentStore.updateFormValue('unit_number', value)}
                    error={equipmentStore.formErrors['unit_number']}
                    onSubmit={createEquipment}
                    fill="horizontal"
                  />
                </Box>
                <Box direction="row" gap="1rem">
                  <Input
                    id="model_year"
                    label="Model Year"
                    value={equipmentStore.formValues.model_year}
                    setValue={(value) => equipmentStore.updateFormValue('model_year', value)}
                    error={equipmentStore.formErrors['model_year']}
                    onSubmit={createEquipment}
                    fill="horizontal"
                    type="number"
                    required
                  />
                  <Input
                    id="manufacturer"
                    label="Manufacturer"
                    value={equipmentStore.formValues.manufacturer}
                    setValue={(value) => equipmentStore.updateFormValue('manufacturer', value)}
                    error={equipmentStore.formErrors['manufacturer']}
                    onSubmit={createEquipment}
                    fill="horizontal"
                    required
                  />
                  <Input
                    id="model_number"
                    label="Model Number"
                    value={equipmentStore.formValues.model_number}
                    setValue={(value) => equipmentStore.updateFormValue('model_number', value)}
                    error={equipmentStore.formErrors['model_number']}
                    onSubmit={createEquipment}
                    fill="horizontal"
                  />
                </Box>
              </FormCardSection>
              <Line margin="xsmall" />
              <FormCardSection>
                {userStore.isInternalUser && isOrWa && (
                  <Box direction="row" gap="1rem">
                    {renderFirstDayInService()}
                    {renderFirstReportingPeriod()}
                  </Box>
                )}
                <Box direction="row" gap="1rem">
                  {renderMetered()}
                  {userStore.isInternalUser && renderBookAndClaim()}
                  {userStore.isInternalUser && !isOrWa && renderFirstReportingPeriod()}
                  {userStore.isExternalUser && isOrWa && renderFirstDayInService()}
                </Box>
                {!equipmentStore.formValues.is_metered && (
                  <Box direction="row" gap="1rem">
                    <Input
                      id="battery_capacity_rating_ah"
                      label="Battery Capacity (Ah)"
                      value={equipmentStore.formValues.battery_capacity_rating_ah}
                      setValue={(value) => equipmentStore.updateFormValue('battery_capacity_rating_ah', value)}
                      error={equipmentStore.formErrors['battery_capacity_rating_ah']}
                      onSubmit={createEquipment}
                      fill="horizontal"
                      type="number"
                      placeholder={
                        equipmentStore.formValues.is_metered ? 'Not applicable to metered equipment.' : undefined
                      }
                      disabled={equipmentStore.formValues.is_metered}
                      required={!equipmentStore.formValues.is_metered}
                      hideOptionalText
                    />
                    <Input
                      id="voltage"
                      label="Voltage"
                      value={equipmentStore.formValues.voltage}
                      setValue={(value) => equipmentStore.updateFormValue('voltage', value)}
                      error={equipmentStore.formErrors['voltage']}
                      onSubmit={createEquipment}
                      fill="horizontal"
                      type="number"
                      placeholder={
                        equipmentStore.formValues.is_metered ? 'Not applicable to metered equipment.' : undefined
                      }
                      disabled={equipmentStore.formValues.is_metered}
                      required={!equipmentStore.formValues.is_metered}
                      hideOptionalText
                    />
                  </Box>
                )}
              </FormCardSection>
              <Line margin="xsmall" />
              <Box flex="grow">
                <Input
                  id="comments"
                  label="Comments"
                  value={equipmentStore.formValues.comments ?? ''}
                  setValue={(value) => equipmentStore.updateFormValue('comments', value)}
                  error={equipmentStore.formErrors['comments']}
                  onSubmit={createEquipment}
                  height={userStore.isExternalUser ? '12rem' : undefined}
                  componentType="textArea"
                  centerLabel={false}
                />
              </Box>
            </Box>
            <Line direction="vertical" margin="3rem" />
            <Box gap="1rem" width="40%">
              <FormCardSection>
                <Select
                  id="facility_id"
                  label="Facility"
                  value={equipmentStore.formValues.facility_id}
                  setValue={(value) => equipmentStore.updateFormValue('facility_id', value)}
                  onMore={nextFacilityPage}
                  options={facilityOptions}
                  error={equipmentStore.formErrors['facility_id']}
                  required
                />
              </FormCardSection>
              {userStore.isInternalUser && <Line margin="0.5rem" />}
              {userStore.isInternalUser && (
                <FormCardSection title="FSE">
                  <Select
                    id="fse_registration_status_id"
                    label="FSE Registration Status"
                    placeholder="Choose..."
                    value={equipmentStore.formValues.fse_registration_status_id}
                    setValue={(value) => equipmentStore.updateFormValue('fse_registration_status_id', value)}
                    options={(fseRegistrationStatuses ?? []).map((s) => ({ label: s.name, value: s.id }))}
                    error={equipmentStore.formErrors['fse_registration_status_id']}
                    required
                  />

                  <Input
                    id="fse_id"
                    label="FSE ID"
                    value={equipmentStore.formValues.fse_id ?? ''}
                    setValue={(value) => equipmentStore.updateFormValue('fse_id', value)}
                    error={equipmentStore.formErrors['fse_id']}
                    onSubmit={createEquipment}
                    required={equipmentStore.isApproved}
                  />

                  <Input
                    id="fse_ru"
                    label="FSE RU"
                    value={equipmentStore.formValues.fse_ru ?? ''}
                    setValue={(value) => equipmentStore.updateFormValue('fse_ru', value)}
                    error={equipmentStore.formErrors['fse_ru']}
                    onSubmit={createEquipment}
                  />
                </FormCardSection>
              )}
              {userStore.isInternalUser && <Line margin="0.5rem" />}
              {userStore.isInternalUser && (
                <FormCardSection title="Fuel Pathway">
                  <Select
                    id="fuel_pathway_id"
                    label="Fuel Pathway Code"
                    placeholder={!isLoadingPathways ? 'Choose...' : 'Loading...'}
                    value={!isLoadingPathways ? equipmentStore.formValues.fuel_pathway_id : ''}
                    setValue={(value) => equipmentStore.updateFormValue('fuel_pathway_id', value)}
                    options={equipmentStore.fuelPathwayOptions}
                    error={equipmentStore.formErrors['fuel_pathway_id']}
                    onSearch={searchPathways}
                    searchPlaceholder="Filter by Code..."
                    emptySearchMessage={errorMessages.fuelPathwayNameSearchNoResult}
                    required={equipmentStore.isApproved}
                    disabled={!userStore.isAdminUser || isLoadingPathways}
                    fill="horizontal"
                  />
                </FormCardSection>
              )}
              <Line margin="0.5rem" />
              <FormCardSection title="Location">
                <Box direction="row" gap="1rem">
                  <Input
                    id="latitude"
                    label="Latitude"
                    value={equipmentStore.formValues.latitude}
                    type="number"
                    setValue={(value) => {
                      equipmentStore.updateFormValue('latitude', value);
                      setIsUserLocation(true);
                    }}
                    error={equipmentStore.formErrors['latitude']}
                    onSubmit={createEquipment}
                    fill="horizontal"
                    placeholder="DD.DDDDDD°"
                    required
                  />
                  <Input
                    id="longitude"
                    label="Longitude"
                    value={equipmentStore.formValues.longitude}
                    type="number"
                    setValue={(value) => {
                      equipmentStore.updateFormValue('longitude', value);
                      setIsUserLocation(true);
                    }}
                    error={equipmentStore.formErrors['longitude']}
                    onSubmit={createEquipment}
                    fill="horizontal"
                    placeholder="DDD.DDDDDD°"
                    required
                  />
                </Box>
              </FormCardSection>
            </Box>
          </Box>
        </Form>
      </FormCard>
    </FormPage>
  );
});
